export const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="39.844" height="31.875" viewBox="0 0 39.844 31.875"><g transform="translate(-254.773 -1717.785)"><path d="M278.681,1749.66a1.992,1.992,0,0,1-1.409-3.4l12.537-12.537-12.537-12.537a1.992,1.992,0,1,1,2.817-2.817l13.943,13.942a2,2,0,0,1,0,2.82l-13.945,13.945A1.966,1.966,0,0,1,278.681,1749.66Z"/><path d="M292.624,1735.71H256.765a1.992,1.992,0,1,1,0-3.984h35.859a1.992,1.992,0,1,1,0,3.984Z"/></g></svg>
)

export const LeftArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="39.844" height="31.875" viewBox="0 0 39.844 31.875"><g transform="translate(294.617 1749.66) rotate(180)"><path d="M278.681,1749.66a1.992,1.992,0,0,1-1.409-3.4l12.537-12.537-12.537-12.537a1.992,1.992,0,1,1,2.817-2.817l13.943,13.942a2,2,0,0,1,0,2.82l-13.945,13.945A1.966,1.966,0,0,1,278.681,1749.66Z" /><path d="M292.624,1735.71H256.765a1.992,1.992,0,1,1,0-3.984h35.859a1.992,1.992,0,1,1,0,3.984Z" /></g></svg>
)

export const Disconnect = () => (

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_651_490)">
        <g clipPath="url(#clip1_651_490)">
          <path d="M6.11719 6.74939L8.66103 4.20546L9.39474 4.93914L6.85089 7.48307L6.11719 6.74939Z" fill="currentColor"/>
          <path d="M8.66038 4.48839L9.11118 4.93919L6.85038 7.19999L6.39958 6.74919L8.66038 4.48839ZM8.66038 3.92279L8.37758 4.20559L6.11678 6.46639L5.83398 6.74919L6.11678 7.03199L6.56758 7.48279L6.85038 7.76559L7.13318 7.48279L9.39398 5.22199L9.67678 4.93919L9.39398 4.65639L8.94318 4.20559L8.66038 3.92279Z" fill="#565656"/>
          <path d="M8.51758 9.14941L11.0614 6.60548L11.7951 7.33916L9.25129 9.8831L8.51758 9.14941Z" fill="currentColor"/>
          <path d="M11.0608 6.88842L11.5116 7.33921L9.25078 9.60001L8.79997 9.14921L11.0608 6.88842ZM11.0608 6.32281L10.778 6.60561L8.51717 8.86641L8.23438 9.14921L8.51717 9.43201L8.96797 9.88281L9.25078 10.1656L9.53357 9.88281L11.7944 7.62201L12.0772 7.33921L11.7944 7.05642L11.3436 6.60561L11.0608 6.32281Z" fill="#565656"/>
          <path d="M8.17578 3.14482L10.1046 1.21602C10.4646 0.85642 10.943 0.65802 11.4518 0.65802C11.9606 0.65802 12.439 0.85642 12.799 1.21602L13.3326 1.75002L14.4006 0.68282L15.3178 1.60002L14.2506 2.66722L14.7846 3.20082C15.5274 3.94362 15.5274 5.15242 14.7846 5.89562L12.8558 7.82442L8.17578 3.14482Z" fill="currentColor"/>
          <path d="M11.4518 0.858024C11.9074 0.858024 12.3354 1.03522 12.6578 1.35762L13.0502 1.75002L13.333 2.03282L13.6158 1.75002L14.4002 0.965624L15.0346 1.60002L14.2502 2.38442L13.9674 2.66722L14.2502 2.95002L14.6426 3.34242C14.9646 3.66442 15.1422 4.09282 15.1422 4.54842C15.1422 5.00402 14.965 5.43202 14.6426 5.75442L12.8554 7.54162L8.45858 3.14482L10.2458 1.35762C10.5678 1.03562 10.9962 0.858024 11.4518 0.858024ZM14.4002 0.400024L13.333 1.46722L12.9402 1.07482C12.529 0.663624 11.9902 0.458024 11.4514 0.458024C10.9126 0.458024 10.3738 0.663624 9.96258 1.07482L7.89258 3.14482L12.855 8.10722L14.925 6.03722C15.7474 5.21522 15.7474 3.88202 14.925 3.05962L14.5326 2.66722L15.6002 1.60002L14.4002 0.400024Z" fill="#565656"/>
          <path d="M6.53711 3.93842L7.36351 3.11162C7.62111 2.85402 7.95071 2.70642 8.26791 2.70642C8.49271 2.70642 8.68551 2.78122 8.82671 2.92202L13.0787 7.17402C13.4295 7.52522 13.3447 8.18162 12.8887 8.63722L12.0619 9.46362L6.53711 3.93842Z" fill="currentColor"/>
          <path d="M8.26791 2.90641C8.38671 2.90641 8.55591 2.93361 8.68551 3.06321L12.9375 7.31521C13.2059 7.58361 13.1191 8.12441 12.7475 8.49561L12.0623 9.18081L6.81951 3.93841L7.50471 3.25321C7.72191 3.03601 8.00711 2.90641 8.26791 2.90641ZM8.26791 2.50641C7.91311 2.50641 7.52711 2.66521 7.22191 2.97041L6.25391 3.93841L12.0623 9.74681L13.0303 8.77881C13.5651 8.24401 13.6499 7.46241 13.2203 7.03281L8.96831 2.78081C8.78391 2.59601 8.53431 2.50641 8.26791 2.50641Z" fill="#565656"/>
          <path d="M4.47153 15.4192C3.96273 15.4192 3.48433 15.2208 3.12433 14.8612L2.59073 14.3272L1.60033 15.3172L0.683131 14.4L1.67313 13.41L1.13913 12.876C0.396331 12.1332 0.396331 10.9244 1.13913 10.1812L3.06793 8.25238L7.74753 12.932L5.81873 14.8608C5.45873 15.2208 4.98033 15.4192 4.47153 15.4192Z" fill="currentColor"/>
          <path d="M3.06766 8.53557L7.46446 12.9324L5.67726 14.7196C5.35526 15.0416 4.92686 15.2192 4.47126 15.2192C4.01566 15.2192 3.58766 15.042 3.26526 14.7196L2.87286 14.3272L2.59006 14.0444L2.30726 14.3272L1.60006 15.0344L0.965659 14.4L1.67286 13.6928L1.95566 13.41L1.67286 13.1272L1.28046 12.7348C0.615659 12.07 0.615659 10.988 1.28046 10.3228L3.06766 8.53557ZM3.06766 7.96997L0.997659 10.04C0.175259 10.8624 0.175259 12.1952 0.997659 13.0176L1.39006 13.41L0.400059 14.4L1.60006 15.6L2.59006 14.61L2.98246 15.0024C3.39366 15.4136 3.93246 15.6192 4.47126 15.6192C5.01006 15.6192 5.54886 15.4136 5.96006 15.0024L8.03006 12.9324L3.06766 7.96997Z" fill="#565656"/>
          <path d="M7.71241 13.404C7.47681 13.404 7.25841 13.3156 7.09801 13.1552L2.84601 8.90318C2.49521 8.55198 2.51881 7.95718 2.89921 7.57678L3.58921 6.88678L9.11481 12.412L8.42481 13.102C8.23281 13.294 7.97321 13.404 7.71241 13.404Z" fill="currentColor"/>
          <path d="M3.58821 7.1696L8.83101 12.4124L8.28261 12.9608C8.12781 13.1156 7.91981 13.204 7.71181 13.204C7.52941 13.204 7.36141 13.1364 7.23861 13.0136L2.98661 8.7616C2.71341 8.4884 2.73741 8.0204 3.03981 7.718L3.58821 7.1696ZM3.58821 6.604L2.75701 7.4352C2.29781 7.8944 2.27421 8.6148 2.70381 9.0448L6.95581 13.2968C7.16141 13.5024 7.43341 13.604 7.71181 13.604C8.01541 13.604 8.32581 13.4828 8.56541 13.2436L9.39661 12.4124L3.58821 6.604Z" fill="#565656"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_651_490">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
        <clipPath id="clip1_651_490">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
)
