import { Field, useFormState } from "react-final-form"
import { FormattedInput, StyledField } from "components/atoms/form"

import { convertValue } from 'utils/convertBtcSats'
import { useLogic } from '../../../../utils/logicContext';
import { getUnitName, numberWithCommas } from '../../../../utils/currency';

const convertedText = (isSatoshis, goal) => {
  if (isSatoshis) {
    return `= ${goal ? (convertValue(true, false, goal)).toLocaleString(undefined, { maximumFractionDigits: 8 }) : '?'} BTC`;
  } else {
    return `= ${goal ? (convertValue(false, true, goal)).toLocaleString(undefined, { maximumFractionDigits: 0 }) : '?'} sats`;
  }
}

export const validateGoal = ({ goal: value, isSatoshis }, inputsProps, {
  satsLimit,
  BTCLimit,
  currentStack
}) => {
  value = Number.parseFloat(value)
  if (!value) return 'Must specify a number';
  if (value < 0) return 'Must not be a negative number';
  if (value <= currentStack) return 'SatsGoal must be greater than starting stack'
  if (!isSatoshis && value > BTCLimit) return inputsProps.goalAmountBTCOverLimitError;
  if (isSatoshis && value > satsLimit) return inputsProps.goalAmountSatsOverLimitError;
}

const GoalField = ({ inputsData }) => {
  const { values: { goal, isSatoshis }, errors } = useFormState();
  const logic = useLogic();

  return (
    <Field
      name="goal"
      type="number"
      initialValue={inputsData.defaults.goalSats}
    >
      {({ input, meta }) => (
        <StyledField
          id="goal"
          label={inputsData.goalAmountLabel}
          error={meta.error}
          help={inputsData.helpTexts.goal}
          supplementaryText={convertedText(isSatoshis, goal)}
        >
          <FormattedInput
            {...input}
            id="goal"
            step={isSatoshis ? 1 : 0.00000001}
            min={isSatoshis ? 1 : 0.00000001}
            max={isSatoshis ? 1 * logic.SatsSupplyLimit : 1 * logic.BTCSupplyLimit}
            format={val => `${val ? numberWithCommas(val) : 0} ${getUnitName(val, isSatoshis)}`}
            $error={meta.error}
          />
        </StyledField>
      )}
    </Field>
  );
}

export default GoalField;
