import { FormattedInput } from 'components/atoms/form';
import React from 'react';

const stripLettersFromSymbol = (str) =>
  str.replace(/^(?<sign>-)?(US|A|CA|JP)+/g, '$<sign>');

const formatValueToCurrencyString = (value, currency, options) => {
  return value.toLocaleString(undefined, {
    style: 'currency',
    currency,
    ...options,
  }) ?? '';
};

export const toCurrencyString = (value, currency = 'USD', options = {}) => {
  if (value === null || value === undefined || value === '' || isNaN(value)) {
    return '';
  }

  const { maximumFractionDigits = 2 } = options;
  let calcVal = Number(value);

  // Add check for -0
  if (Object.is(calcVal, -0)) {
    calcVal = 0;
  }

  if (calcVal > -(1 / 10 ** maximumFractionDigits) && calcVal < 0) {
    calcVal = 0;
  }

  const outString = formatValueToCurrencyString(calcVal, currency, options);
  return stripLettersFromSymbol(outString);
};

const toSigFigsRounded = (
  num,
  divisor,
  suffixLetter,
  maximumSignificantDigits
) => {
  const adjusted =
    Number(num.toPrecision(maximumSignificantDigits || 3)) / divisor;
  return `${adjusted.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })}${suffixLetter}`;
};

export const formatWithLetters = (
  num,
  { maximumSignificantDigits, ...other } = {}
) => {
  if (num >= 1_000_000_000_000) {
    return toSigFigsRounded(
      num,
      1_000_000_000_000,
      'T',
      maximumSignificantDigits
    );
  }
  if (num >= 1_000_000_000) {
    return toSigFigsRounded(num, 1_000_000_000, 'B', maximumSignificantDigits);
  }
  if (num >= 1_000_000) {
    return toSigFigsRounded(num, 1_000_000, 'M', maximumSignificantDigits);
  }
  if (num >= 1_000) {
    return toSigFigsRounded(num, 1_000, 'k', maximumSignificantDigits);
  }
  return num.toLocaleString(undefined, { maximumSignificantDigits, ...other });
};

export const toAbbreviatedCurrencyString = (value, currency, options = {}) => {
  const normalValue = toCurrencyString(value, currency, options);
  const formatted = formatWithLetters(value, options);
  if (formatted) return normalValue.replace(/[\d.,]+/g, formatted);
  return normalValue;
};

export const getLocaleCurrency = (defaultCurrency) => {
  if (typeof window !== 'undefined') {
    if (window.navigator.language === 'en-GB') return 'GBP';
  }
  return defaultCurrency || 'USD';
};

export function getCurrencySymbol(currency) {
  return stripLettersFromSymbol(
    (0)
      .toLocaleString(undefined, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim()
  );
}

export const CurrencyInput = React.forwardRef(({ currency, ...props }, ref) => {
  const [hasChanged, setHasChanged] = React.useState(false);

  const handleFocus = (e) => {
    if (!hasChanged) {
      e.target.value = '';
    }
  };

  const handleBlur = (e) => {
    if (!hasChanged && e.target.value === '') {
      e.target.value = props.defaultValue;
    }
  };

  const handleChange = (e) => {
    setHasChanged(true);
    if (props.onChange) {
      props.onChange(e);
    }
    setHasChanged(false);
  };
  return (
    <FormattedInput
      ref={ref}
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      format={(val) =>
        val !== ''
          ? toCurrencyString(val, currency)
          : getCurrencySymbol(currency)
      }
      leftContentEditing={getCurrencySymbol(currency)}
      step={0.01}
    />
  );
});

export function numberWithCommas(x) {
  if (isNaN(x)) return x;
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function getUnitName(amount, isSatoshis) {
  if (isSatoshis) {
    if (amount == 1) {
      return 'sat';
    }
    return 'sats';
  } else {
    return 'BTC';
  }
}

export function scientificToNumber(num) {
  return Number(num)
    .toFixed(8)
    .replace(/\.?0+$/, '');
}

const currencySymbolsToCoincapIds = {
  'USD': 'united-states-dollar',
  'GBP': 'british-pound-sterling',
  'EUR': 'euro',
  'JPY': 'japanese-yen',
  'AUD': 'australian-dollar',
  'CAD': 'canadian-dollar',
};

export function getCoincapCurrencyCode(currencySymbol) {
  return currencySymbolsToCoincapIds[currencySymbol];
}
