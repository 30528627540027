import Head from 'next/head'
import React from 'react';

const SEO = ({
    title: propTitle,
    metaTitle,
    metaDescription,
    twitterCard,
    twitterSite,
    twitterCreator,
    openGraphImage,
    noindex,
    jsonSchema,
}) => {
    const title = propTitle || metaTitle;
    const ogImage = openGraphImage?.data?.attributes;

    return (
        <Head>
            {title && (
                <>
                    <title key="title">{title}</title>
                    <meta key="og:title" property="og:title" content={title} />
                </>
            )}
            {metaDescription && (
                <>
                    <meta key="description" name="description" content={metaDescription} />
                    <meta key="twitter:description" name="twitter:description" content={metaDescription} />
                </>
            )}
            {twitterCard && <meta key="twitter:card" name="twitter:card" content={twitterCard} />}
            {twitterSite && <meta key="twitter:site" name="twitter:site" content={twitterSite} />}
            {twitterCreator && <meta key="twitter:creator" name="twitter:creator" content={twitterCreator} />}
            {ogImage && ogImage?.url && (
                <>
                    <meta key="og:image" property="og:image" content={ogImage.url} />
                    {ogImage.width && <meta key="og:image:width" property="og:image:width" content={ogImage.width} />}
                    {ogImage.height && <meta key="og:image:height" property="og:image:height" content={ogImage.height} />}
                    {ogImage.alternativeText && <meta key="og:image:alt" property="og:image:alt" content={ogImage.alternativeText} />}
                    {ogImage.mime && <meta key="og:image:type" property="og:image:type" content={ogImage.mime} />}
                </>
            )}
            {jsonSchema && 
                <script key="jsonSchema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }} />
            }
            <meta key="robots" property="robots" content={noindex ? 'noindex' : 'index follow'} />
        </Head>
    )
}

export default SEO;
