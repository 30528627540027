import { Box, Composition } from 'atomic-layout';
import PageTitle from 'components/layout/pageTitle';
import SEO from 'components/seo';
import dynamic from 'next/dynamic';
import { enableScroll } from 'utils/utils';
import { getFooterProps } from 'components/layout/footer';
import { getHeaderProps } from 'components/layout/header';
import { getLoaderProps } from 'components/loader';
import strapi from 'utils/strapi';
import styled from 'styled-components';
import { useState } from 'react';
import { validateGoal } from 'components/content/calculator/inputs/goal';
import { validateCurrentStack } from '../components/content/calculator/inputs/currentStack';
import { LogicContext } from '../utils/logicContext';
import { useAtomValue } from 'jotai';
import * as calculator from '../atoms/calculator';

const BitcoinValueBox = dynamic(
  () => import('components/content/hero/bitcoinValueBox'),
  {
    loading: () => <div style={{ height: '100px' }} />,
  }
);
const CTAComponent = dynamic(() => import('components/content/cta'));
const Calculation = dynamic(() =>
  import('components/content/calculator/calculation')
);
const CalculatorInputs = dynamic(() =>
  import('components/content/calculator/inputs')
);
const EmailSignupComponent = dynamic(() =>
  import('components/content/email-signup')
);
const GlassBox = dynamic(() => import('components/atoms/GlassBox'), {
  loading: () => <div style={{ height: '650px' }} />,
});
const GraphsComponent = dynamic(() =>
  import('components/content/calculator/outputs/graphs')
);
const LatestBlogComponent = dynamic(() =>
  import('components/content/latest-blog')
);
const Modal = dynamic(() => import('components/atoms/Modal'));
const PredictionInputs = dynamic(() =>
  import('components/content/calculator/inputs/predictionInputs')
);
const SingleStatsComponent = dynamic(() =>
  import('components/content/calculator/outputs/single-stats')
);
const SummaryTextComponent = dynamic(() =>
  import('components/content/calculator/outputs/summary-text')
);
const TableComponent = dynamic(() =>
  import('components/content/calculator/outputs/results-table')
);
const Form = dynamic(async () => (await import('react-final-form')).Form);

const areasMobile = `
bitcoinValue
inputs
summaryText
graph
singleStats
callToAction
tableOutput
latestBlog
emailSignup
`;

const areasTablet = ({ ctaSection }) => `
inputs      inputs        bitcoinValue      bitcoinValue
inputs      inputs        summaryText       summaryText
inputs      inputs        singleStats       singleStats
inputs      inputs        ${
  ctaSection?.visible
    ? 'callToAction  callToAction'
    : 'singleStats  singleStats'
}
graph       graph         graph             graph
tableOutput tableOutput   tableOutput       tableOutput
latestBlog  latestBlog    latestBlog        latestBlog
emailSignup emailSignup   emailSignup       emailSignup
`;

const areasDesktop = ({ ctaSection }) => `
inputs        graph         graph
bitcoinValue  summaryText   ${
  ctaSection?.visible ? 'callToAction' : 'summaryText'
}
singleStats   tableOutput   tableOutput
latestBlog    latestBlog    latestBlog
emailSignup   emailSignup   emailSignup
`;

const StyledComposition = styled(Composition)`
  padding: 1rem;
  padding-bottom: 0;
  box-sizing: border-box;
  row-gap: 3rem;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.lg.minWidth}) {
    row-gap: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export default function Home({
  seoData,
  heroData,
  inputsData,
  outputsProps,
  latestBlog,
  ctaSection,
  emailSignupBox,
  workOfflineSection,
  logic,
}) {
  const [showPricePredictionModal, setShowPricePredictionModal] =
    useState(false);
  const startingStack = useAtomValue(calculator.startingStackAtom);
  const allInputsData = { ...inputsData, startingStack };
  return (
    <>
      <SEO {...seoData} />
      <LogicContext.Provider value={logic}>
        <Form
          onSubmit={() => {}}
          validate={(values) => {
            const sharingValidation = {};
            if (values.useStackingAmount)
              return {
                ...sharingValidation,
                currentStack: validateCurrentStack(values, startingStack, {
                  satsLimit: logic.SatsSupplyLimit,
                  BTCLimit: logic.BTCSupplyLimit,
                  currentStack: startingStack,
                }),
              };
            return {
              goal: validateGoal(values, allInputsData, {
                satsLimit: logic.SatsSupplyLimit,
                BTCLimit: logic.BTCSupplyLimit,
                currentStack: startingStack,
              }),
              currentStack: validateCurrentStack(values, startingStack, {
                satsLimit: logic.SatsSupplyLimit,
                BTCLimit: logic.BTCSupplyLimit,
                currentStack: startingStack,
                checkGoal: true,
              }),
              ...sharingValidation,
            };
          }}
        >
          {({ values }) => (
            <>
              <Box flex align="center" marginBottom={5} marginBottomSmDown={3}>
                <PageTitle
                  title={heroData.heading}
                  description={
                    values.useStackingAmount
                      ? heroData.descriptionPredictByInvestment
                      : heroData.descriptionPredictByGoal
                  }
                  $marginBottom={0}
                />
              </Box>
              <Box flex justifyContent="center">
                <StyledComposition
                  areas={areasMobile}
                  areasSm={areasTablet({ ctaSection })}
                  areasXl={areasDesktop({ ctaSection })}
                  gapCol={3}
                  paddingHorizontalLg={2}
                  templateColsSm="1fr 1fr 1fr 1fr"
                  templateRowsSm="repeat(min-content)"
                  templateColsXl="25% 1fr 1fr"
                  maxWidth={'1500px'}
                  flexGrow={'1'}
                  height="100%"
                >
                  {({
                    Inputs,
                    SummaryText,
                    BitcoinValue,
                    SingleStats,
                    Graph,
                    TableOutput,
                    EmailSignup,
                    LatestBlog,
                    CallToAction,
                  }) => (
                    <>
                      {showPricePredictionModal && (
                        <Modal
                          title={allInputsData.predictionsModalTitle}
                          onClose={() => {
                            enableScroll();
                            setShowPricePredictionModal(false);
                          }}
                        >
                          <PredictionInputs inputsData={allInputsData} />
                        </Modal>
                      )}
                      <Calculation>
                        <SummaryText
                          flex
                          justifyContent="center"
                          maxWidth="calc(100vw - 2rem)"
                        >
                          <SummaryTextComponent
                            byGoalSummaryText={outputsProps.byGoalSummaryText}
                            byInvestmentAmountSummaryText={
                              outputsProps.byInvestmentAmountSummaryText
                            }
                          />
                        </SummaryText>
                        <BitcoinValue
                          flex
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          justifyContentSm="center"
                          maxWidth="calc(100vw - 2rem)"
                        >
                          <BitcoinValueBox />
                        </BitcoinValue>
                        <Inputs>
                          <GlassBox padding="1rem 1rem 0 1rem" minHeight="auto" height="100%">
                            <CalculatorInputs
                              workOfflineSection={workOfflineSection}
                              inputsData={allInputsData}
                              showPricePredictionModal={
                                showPricePredictionModal
                              }
                              setShowPricePredictionModal={
                                setShowPricePredictionModal
                              }
                            />
                          </GlassBox>
                        </Inputs>
                        <SingleStats maxWidth="calc(100vw - 2rem)">
                          <SingleStatsComponent
                            singleStatsProps={outputsProps.singleStats}
                          />
                        </SingleStats>
                        <Graph maxWidth="calc(100vw - 2rem)" flex>
                          <GraphsComponent
                            graphProps={outputsProps.graphs}
                            graphToggleProps={outputsProps.graphToggle}
                          />
                        </Graph>
                        <TableOutput
                          maxWidth="calc(100vw - 2rem)"
                          marginTopXsDown={-1}
                        >
                          <TableComponent tableProps={outputsProps.table} />
                        </TableOutput>
                      </Calculation>
                      <EmailSignup flex justifyContentMd="center">
                        <EmailSignupComponent
                          isFullWidth={false}
                          {...emailSignupBox}
                        />
                      </EmailSignup>
                      {latestBlog && (
                        <LatestBlog
                          id="scrollbar-blogs"
                          style={{ overflowX: 'scroll' }}
                          flex
                          width="100%"
                          justifyContentXl="start"
                        >
                          <LatestBlogComponent blogs={latestBlog} />
                        </LatestBlog>
                      )}
                      {!!ctaSection?.visible && (
                        <CallToAction>
                          <CTAComponent {...ctaSection} />
                        </CallToAction>
                      )}
                    </>
                  )}
                </StyledComposition>
              </Box>
            </>
          )}
        </Form>
      </LogicContext.Provider>
    </>
  );
}

export async function getStaticProps() {
  const { data } = await strapi('/api/calculator', {
    populate: [
      'seo',
      'seo.openGraphImage',
      'hero',
      'inputs',
      'inputs.predictionsToggle',
      'inputs.defaults',
      'inputs.helpTexts',
      'inputs.goalDateSelectOptions',
      'inputs.cagrDateSelectOptions',
      'outputs',
      'outputs.table',
      'outputs.singleStats',
      'outputs.graphs',
      'outputs.graphToggle',
      'outputs.byGoalSummaryText',
      'outputs.byInvestmentAmountSummaryText',
      'ctaSection',
      'ctaSection.image',
      'workOffline',
      'Logic',
      'workOffline.instructions',
    ],
  });

  const { data: blogs } = await strapi('/api/blog-posts', {
    populate: ['thumbnailImage'],
    pagination: { limit: 4 },
    sort: ['publishedAt:desc'],
  });

  const {
    data: { attributes: emailSignupBox },
  } = await strapi('/api/email-signup-box', {});

  const latestBlogs = blogs.length > 0 ? blogs : null;

  return {
    props: {
      seoData: data.attributes.seo,
      footerProps: await getFooterProps(),
      headerProps: await getHeaderProps(),
      loaderProps: await getLoaderProps(),
      heroData: data.attributes.hero,
      inputsData: data.attributes.inputs,
      outputsProps: data.attributes.outputs,
      latestBlog: latestBlogs,
      ctaSection: data?.attributes?.ctaSection?.visible
        ? data.attributes.ctaSection
        : {},
      workOfflineSection: data.attributes.workOffline,
      emailSignupBox: emailSignupBox || {},
      logic: data.attributes.Logic || {},
    },
  };
}
