import styled from 'styled-components';
import MarkdownText from '../atoms/markdown';

const GradientText = styled.span`
  background: ${({ theme }) => theme.colors.primaryGradient};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? $marginBottom : 0)};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    padding: 0 1rem;
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom ? `calc(${$marginBottom} / 2)` : 0};
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  text-align: center;
  margin: initial;
  font-weight: initial;
  line-height: 1.6;
  font-size: 1.6rem;
  font-weight: 500;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    font-size: 1.5rem;
  }
  color: ${({ theme }) => theme.colors.text};

  max-width: 800px;
`;

export const TitleH1 = styled.h1`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: initial;
  margin: initial;
  text-align: center;
  font-size: 46px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    font-size: 3rem;
  }
  color: ${({ theme }) => theme.colors.text};
`;

const StyledMarkdownContainer = styled.div`
  max-width: 800px;
  text-align: center;
  p {
    font-family: Inter, sans-serif;
    text-align: center;
    margin: initial;
    line-height: 1.6;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    max-width: 800px;
  }
`

const PageTitle = ({ title, description, $marginBottom }) => {
  const [before, gradientText, after] = (title || '').split('<gradient>');

  return (
    <Wrapper $marginBottom={$marginBottom}>
      <Inner>
        <TitleH1>
          {before}
          <GradientText>{gradientText}</GradientText>
          {after}
        </TitleH1>
        {/* For some reason using Description styled component causes error */}
        <StyledMarkdownContainer style={{  }}>
          <MarkdownText>{description}</MarkdownText>
        </StyledMarkdownContainer>
      </Inner>
    </Wrapper>
  );
};

export default PageTitle;
