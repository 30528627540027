export const sentenceCase = (string) => {
  const lowCase = string.toLowerCase();
  const capitalize = lowCase[0].toUpperCase() + lowCase.slice(1);
  return capitalize;
}

export const colorFromValue = number => {
  if (number > 0) return '#7DD346';
  if (number < 0) return '#D0342C';
  return 'inherit';
}

export function enableScroll() {
  document.body.classList.remove("stop-scrolling");
}

export function disableScroll() {
  document.body.classList.add("stop-scrolling");
}
