import {Decimal} from 'decimal.js';
import {scientificToNumber} from './currency';

export const SATOSHI_FACTOR = 100000000;

export const convertValue = (previousIsSatoshi, newIsSatoshi, value) => {
    if (newIsSatoshi === true && previousIsSatoshi === false) {
        return value * SATOSHI_FACTOR;
    }
    if (newIsSatoshi === false && previousIsSatoshi === true) {
        return value / SATOSHI_FACTOR;
    }
    return value;
}

export const onToggle = (form, previousIsSatoshi, newIsSatoshi) => {
    const { values: { goal } } = form.getState();
    form.change('goal', convertValue(previousIsSatoshi, newIsSatoshi, goal));
}

export const toSat = (val) => {
    if (val === '') return ''
    if (!val) return '0'

    return scientificToNumber(new Decimal(val)?.mul(SATOSHI_FACTOR).toNumber()).toString()
}

export const toBTC = (val) => {
    if (val === '') return ''
    if (!val) return '0'

    return scientificToNumber(new Decimal(val).dividedBy(SATOSHI_FACTOR).toNumber()).toString()
}
