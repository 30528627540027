import { Field, useFormState, useForm } from "react-final-form"
import { StyledField as _StyledField } from "components/atoms/form"
import { useCalculation } from "components/content/calculator/calculationContext"
import styled from 'styled-components';
import { Button } from '../../../atoms/Buttons';
import { Disconnect } from '../../../atoms/SVG';
import { useMitt } from 'react-mitt';
import { FormattedInput } from '../../../atoms/form';
import { useEffect, useState } from 'react';
import { toBTC, toSat } from '../../../../utils/convertBtcSats';
import { useLogic } from '../../../../utils/logicContext';
import { getUnitName, numberWithCommas, scientificToNumber } from '../../../../utils/currency';


const StyledField = styled(_StyledField)`
  position: relative;
`

const StyledButton = styled(Button)`
  min-height: unset;
  height: unset;
  position: absolute;
  top: -4px;
  right: 3.5rem;
  font-weight: 800;
  padding: 0.27rem 1.1rem;
  font-size: 1.2rem;
  font-family: 'Inter', sans-serif;
  line-height: 1.7;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 300ms all;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }
`

export const validateCurrentStack = ({ goal, currentStack: value, isSatoshis }, inputsProps, {
  satsLimit,
  BTCLimit,
  checkGoal
}) => {
  // if (value === '' || value === undefined) return 'Required'
  if (value < 0) return 'Must not be a negative number';
  if (!isSatoshis && Number.parseFloat(value) > Number.parseFloat(BTCLimit)) return inputsProps.goalAmountBTCOverLimitError;
  if (isSatoshis && Number.parseFloat(value) > Number.parseFloat(satsLimit)) return inputsProps.goalAmountSatsOverLimitError;
  if (checkGoal && Number.parseFloat(value) > Number.parseFloat(goal)) {
    return 'Current stack cannot be more than the goal'
  }
}


const CurrentStackField = ({ inputsData, workOfflineSection }) => {
  const { goal, useStackingAmount } = useCalculation();
  const logic = useLogic();
  const { emitter } = useMitt();
  const { values, errors } = useFormState();
  const { change } = useForm();

  const isSatoshis = values.isSatoshis
  const [oldValues, setOldValues] = useState(values)
  const [currentStackValue, setCurrentStackValue] = useState()

  useEffect(() => {
    change('currentStack', 0)
  }, [])

  useEffect(() => {
    if (oldValues.isSatoshis != values.isSatoshis) {
      if (values.isSatoshis) {
        change('currentStack', toSat(values.currentStack))
        setCurrentStackValue(oldVal => toSat(oldVal))
      } else {
        change('currentStack', toBTC(values.currentStack))
        setCurrentStackValue(oldVal => toBTC(oldVal))
      }
      setOldValues(values)
    }
  }, [values]);

  const format = (val) => {
    val = scientificToNumber(val || 0)
    return `${numberWithCommas(val)} ${getUnitName(val, isSatoshis)}`
  }
  const onChange = val => {
    setCurrentStackValue(val)
  }

  // if (isSatoshis && Number(val) > 1 * logic.SatsSupplyLimit) return 'sats limit'
  //               if (!isSatoshis && Number(val) > 1 * logic.BTCSupplyLimit) return 'btc limit'

  // const isCurrentStackError = () => {
  //   if (goal && !useStackingAmount) return startingStack > goal;
  //   if (isSatoshis) return startingStack > 1 * logic.SatsSupplyLimit
  //   return startingStack > 1 * logic.BTCSupplyLimit;
  // };

  // const isError = () => {
  //   if ()
  // }

  return (
    <Field
      name="currentStack"
      type="number"
      validate={val => {
        if (Number(val) < 0) return "Value cannot be negative.";
      }}
    >
      {({ input, meta }) => (
        <StyledField
          label={inputsData.currentStackLabel}
          error={meta.touched && meta.error}
          help={inputsData.helpTexts.stackingAmount} // Change it
        >
          <StyledButton onClick={() => emitter.emit('show-offline-modal')} $primary>
            <Disconnect />{workOfflineSection?.buttonText}
          </StyledButton>
          <FormattedInput
            {...input}
            forcedDefault={''}
            type="number"
            noNegative
            value={currentStackValue}
            noFraction={isSatoshis}
            onChange={(e) => {
              onChange(e.target.value);
              input.onChange(e);
            }}
            forceNumeric
            id="currentStackInput"
            step={isSatoshis ? 1 : 0.00000001}
            min={useStackingAmount || !goal ? (isSatoshis ? 1 : 0.00000001) : goal}
            max={useStackingAmount || !goal ? (isSatoshis ? 1 * logic.SatsSupplyLimit : 1 * logic.BTCSupplyLimit) : goal}
            format={format}
            $error={meta.touched && meta.error}
          />
        </StyledField>
      )}
    </Field>
  );
}

export default CurrentStackField;
