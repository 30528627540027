import Link from 'next/link';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';

const StyledA = styled.a`
  color: ${({ theme, $onPrimaryBg }) =>
    $onPrimaryBg ? theme.colors.linkColours.main : theme.colors.primary};
  text-decoration: underline;

  &:hover {
    color: ${({ theme, $onPrimaryBg }) =>
      $onPrimaryBg ? theme.colors.linkColours.mainHover : theme.colors.text};
  }
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  line-height: 1.6;
  font-size: 1.6rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    font-size: 1.5rem;
  }
`;

const MarkdownText = ({ children, className = '', onPrimaryBg, ...props }) => {
  return (
    <StyledReactMarkdown
      {...props}
      className={`markdown-text-wrapper ${className}`}
      rehypePlugins={[rehypeRaw]}
      components={{
        a({ node, children, href, ...aProps }) {
          const isExternal = href.startsWith('http');
          return (
            <Link href={href} passHref legacyBehavior>
              <StyledA
                {...aProps}
                target={isExternal ? '_blank' : undefined}
                rel={isExternal ? 'noopener' : undefined}
                $onPrimaryBg={onPrimaryBg}
              >
                {children}
              </StyledA>
            </Link>
          );
        },
      }}
    >
      {children}
    </StyledReactMarkdown>
  );
};

export default MarkdownText;
